import store from '../store';
import appRoutes from './app';
import authRoutes from './auth';
import nProgress from 'nprogress';
import { createRouter, createWebHistory } from 'vue-router';
import { pathInRoutes, guestRoutes } from "@/services/utils";

export const routes = [
  ...authRoutes,
  ...appRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    nProgress.start();
  }

  next()
});

router.afterEach(() => {
  nProgress.done();
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth);

  if (requiresAuth && !store.getters["auth/user"]) {
    return next({
      name: "login",
      query: { redirect: to.fullPath }
    });
  }

  if (
    store.getters["auth/user"] &&
    pathInRoutes(guestRoutes(routes), to.name)
  ) {
    return next("/dashboard");
  }

  next();
});

export default router
