<template>
  <div class="font-sans antialiased">
    <router-view />
    <div id="modals"></div>
  </div>
</template>

<style lang="scss">
@import "assets/css/app.scss";
</style>
