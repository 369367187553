import AppButton from '@/components/ui/AppButton';
import AppInputSelect from '@/components/ui/AppInputSelect';
import AppCurrencyInput from '@/components/ui/AppCurrencyInput';
import AppLoadingIndicator from '@/components/ui/AppLoadingIndicator';
import AppLoadingContainer from '@/components/ui/AppLoadingContainer';

const components = {
    'app-button': AppButton,
    'app-input-select': AppInputSelect,
    'app-currency-input': AppCurrencyInput,
    'app-loading-indicator': AppLoadingIndicator,
    'app-loading-container': AppLoadingContainer,
};

export const register = function (app) {
    Object.keys(components).forEach((name) => app.component(name, components[name]));
};