import { format } from 'date-fns';

const filters = {
    titleCase: function (value) {
        let newValue = Array.isArray(value) ? value.join(" ") : value;

        return newValue.split(" ").map(word => `${word[0].toUpperCase()}${word.slice(1)}`).join(" ");
    },
    studelyCase: function (value) {
        return value;
    },
    uppercase: function (value) {
        return value.toUpperCase();
    },
    initials: function (value, limit = 2) {
        const words = value.split(" ").filter(v => !!v);

        return (limit ? words.slice(0, limit) : words).map(word => word[0].toUpperCase()).join('');
    },
    timestamp: function (value, format = 'd MMMM, yyyy HH:mm:ss') {
        return filters.date(new Date(value * 1000), format);
    },
    date: function (value, dateFormat = 'd MMMM, yyyy HH:mm:ss') {
        return format(value, dateFormat);
    },
    dateTime: function (value) {
        let options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        };

        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
    },
    money: function (value, digits = 2) {
        let n = typeof value != "number" ? parseFloat(value) : value;
        return n.toLocaleString("en-US", { minimumFractionDigits: digits });
    },
    ellipsis: function (value, limit = 20) {
        if (typeof value !== "string") return "";

        if (value.length <= limit) {
            return value;
        }

        return value.substring(0, limit) + "...";
    },
    normalizeCurrency: function (value) {
        return parseInt(value.replace(/[,]+/g, ""));
    }
}

export const registerFilters = function (app) {
    app.config.globalProperties.$filters = filters;
};