<template>
  <on-click-outside :action="close">
    <div class="relative">
      <span class="inline-block w-full">
        <button
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          v-bind="$attrs"
          class="relative w-full h-full py-3 pr-10 text-left transition duration-150 ease-in-out bg-white cursor-default focus:outline-none"
          @click="open"
        >
          <slot name="selected-option" v-bind="{ modelValue }">
            <span v-if="modelValue" class="">{{
              getDisplayLabel(modelValue)
            }}</span>
            <span v-else>{{ placeholder }}</span>
          </slot>
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
          >
            <svg
              class="w-5 h-5 text-gray-type-8"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </button>
      </span>
      <div
        class="absolute z-20 w-full mt-1 bg-white rounded-md shadow-lg"
        :class="isOpen ? 'block' : 'hidden'"
      >
        <template v-if="filteredOptions.length > 0">
          <ul
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-item-3"
            class="py-1 overflow-y-auto text-base leading-6 rounded-md shadow-xs focus:outline-none sm:text-sm sm:leading-5"
            style="max-height: 14rem"
            ref="listbox-list"
          >
            <li
              :id="`listbox-item-${index}`"
              role="option"
              v-for="(option, index) in filteredOptions"
              :key="index"
              class="flex items-center px-1 py-1 text-sm select-none"
              @click="select(option)"
            >
              <div
                class="mr-1"
                :class="isSelectedOption(option) ? 'visible' : 'invisible'"
              >
                <svg
                  class="align-baseline"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="none"
                >
                  <path
                    stroke="#6366ab"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11.667 3.5L5.25 9.917 2.333 7"
                  />
                </svg>
              </div>
              <div>
                <slot name="option" v-bind="option">{{
                  getOptionLabel(option)
                }}</slot>
              </div>
            </li>
          </ul>
        </template>
        <template v-else>
          <h1 class="p-2 text-center">No options available</h1>
        </template>
      </div>
    </div>
  </on-click-outside>
</template>

<script>
import OnClickOutside from "../OnClickOutside";

export default {
  props: {
    modelValue: {},
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "label",
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
    dropdownMinWidth: {
      type: String,
    },
    reduce: {
      type: Function,
      default: (option) => option,
    },
  },
  components: {
    OnClickOutside,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  beforeUnmount() {
    if (this.popper !== undefined) {
      this.popper.destroy();
    }
  },
  computed: {
    filteredOptions() {
      return this.options; // .filter(option => option !== this.modelValue);
    },
  },
  methods: {
    open() {
      if (this.isOpen) {
        this.isOpen = false;
        return;
      }
      this.isOpen = true;

      this.$nextTick(() => {
        this.focusItem();
      });
    },
    close() {
      this.isOpen = false;
    },
    select(option) {
      this.$emit("update:modelValue", this.reduce(option));

      this.close();
    },
    isSelectedOption(option) {
      return this.getOptionLabel(option) === this.modelValue;
    },
    getOptionLabel(option) {
      if (typeof option === "object") {
        return option[this.label];
      }

      return option;
    },
     getDisplayLabel(option) {
      if (typeof option === "object") {
        return option[this.label];
      }

      if (this.label) {
        return this.options.find((opt) => opt.id === option)[this.label];
      }

      return option;
    },
    focusItem() {
      const index = this.options.findIndex((option) => {
        return typeof option === "object"
          ? option[this.label] === this.modelValue
          : option === this.modelValue;
      });

      if (index === -1) {
        return;
      }

      this.$refs["listbox-list"].children.item(index).scrollIntoView({
        block: "nearest",
      });
    },
  },
};
</script>
