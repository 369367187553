export const pathInRoutes = function (routes, pathToFind) {
    return routes.map(({ name }) => name).includes(pathToFind);
};

export const guestRoutes = function (routes) {
    return routes.filter(route => route.meta && route.meta.guest);
};

export const debounce = function (callback, wait) {
    let timeout;

    return function (...args) {
        const functionCall = () => callback.apply(this, args);

        clearTimeout(timeout);

        timeout = setTimeout(functionCall, wait);
    };
};

export const localStoreItems = function () {
    return ['__access_token', '__user_id', '__token_expiry'];
};