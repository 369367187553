import app from "./app";
import auth from "./auth";
import { createStore } from 'vuex';

export default createStore({
  modules: {
    auth,
    app
  },
  actions: {
    async resetStates({ commit }) {
      commit("auth/reset");
      commit("app/reset");
    }
  },
})
