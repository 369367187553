<template>
    <div ref="container">
        <slot></slot>
    </div >
</template>
<script>
export default {
  props: {
    action: Function,
  },
  data() {
    return {
      listener: (e) => {
        if (e.target === this.$refs.container || this.$refs.container.contains(e.target)) {
          return;
        }

        this.action();
      },
    };
  },
  unmounted() {
    document.removeEventListener("click", this.listener);
  },
  mounted() {
    document.addEventListener("click", this.listener);
  },
};
</script>
