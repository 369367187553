import axios from "axios";

const Axios = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "Content-Type": "application/json"
    }
});

const authResponseInterceptor = function () {
    Axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error && error.response) {
                if (error.response.status == 401) {
                    localStorage.clear();

                    deleteHeader("Authorization");

                    window.location.href = "/";
                }
            }
            return Promise.reject(error);
        }
    );
}

export const setHeader = function (key, value) {
    Axios.defaults.headers.common[key] = value;
};

export const deleteHeader = function (key) {
    delete Axios.defaults.headers.common[key];
};

export const authorize = function (token) {
    setHeader("Authorization", `Bearer ${token}`);

    authResponseInterceptor();
};

authResponseInterceptor();

export default Axios;
