<template>
  <input type="text" v-bind:value="modelValue" @keypress="ensureNumericKeys" @keyup="formatAsCurrency" />
</template>

<script>
import numeral from "numeral";

export default {
  props: ["modelValue"],

  data() {
    return {};
  },

  methods: {
    formatAsCurrency($event) {
      this.$emit("update:modelValue", numeral($event.target.value).format("0,0"));
    },
    ensureNumericKeys($event) {
      if (
        ($event.keyCode < 48 && $event.keyCode !== 46) ||
        $event.keyCode > 59
      ) {
        $event.preventDefault();
      }
    }
  }
};
</script>
