import axios from "@/services/axios";

const defaultState = () => {
  return {
    wallet: {},
    walletHistory: [],
    busStops: [],
    bookings: [],
    activeBookings: [],
    pastBookings: [],
    notifications: [],
    unreadNotificationsCount: 0
  };
};

const state = defaultState();

const getters = {
  wallet: state => state.wallet,
  walletHistory: state => state.walletHistory,
  busStops: state => state.busStops,
  bookings: state => ([...state.activeBookings, ...state.pastBookings]),
  activeBookings: state => state.activeBookings,
  pastBookings: state => state.pastBookings,
  notifications: state => state.notifications,
  unreadNotificationsCount: state => state.unreadNotificationsCount,
};

const actions = {
  async init({ dispatch }) {
    dispatch('fetchBusStops');

    dispatch('fetchUnreadNotificationsCount');
  },
  fetchBusStops({ commit }) {
    axios.get('/bus-stops').then(res => {
      commit('setBusStops', res.data);
    }).catch(() => {
      // 
    })
  },
  fetchWallet({ commit }) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("__user_id");

      axios
        .get(`/users/${userId}/wallet`)
        .then(res => {
          commit('setWallet', { wallet: res.data.wallet, history: res.data.walletHistory });

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchNotifications({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("__user_id");

      axios
        .get(`/users/${userId}/notifications`, { params })
        .then(res => {
          commit('setNotifications', res.data.data);

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  markNotificationAsRead({ commit, state }, { notificationId }) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("__user_id");

      axios
        .patch(`/users/${userId}/notifications/${notificationId}`, { is_read: 1 })
        .then(res => {
          commit('updateNotification', { notificationId, data: res.data });

          let count = state.unreadNotificationsCount - 1;

          if (count < 0) {
            count = 0;
          }
          
          commit('setUnreadNotificationsCount', count);

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchUnreadNotificationsCount({ commit }) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("__user_id");

      axios
        .get(`/users/${userId}/unread-notifications-count`)
        .then(res => {
          commit('setUnreadNotificationsCount', res.data.count);

          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  bookRoute({ commit }, data) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("__user_id");

      axios
        .post(`/users/${userId}/routes`, data)
        .then(res => {
          commit('addBooking', res.data);

          resolve(res.data);
        })
        .catch(res => {
          reject(res);
        });
    });
  },
  fetchActiveBookings({ commit }) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("__user_id");

      axios
        .get(`/users/${userId}/routes`)
        .then(res => {
          commit('setActiveBookings', res.data);

          resolve(res.data);
        })
        .catch(res => {
          reject(res);
        });
    });
  },
  fetchPastBookings({ commit }) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("__user_id");

      axios
        .get(`/users/${userId}/trips`)
        .then(res => {
          commit('setPastBookings', res.data);

          resolve(res.data);
        })
        .catch(res => {
          reject(res);
        });
    });
  },
  fetchBookings({ dispatch }) {
    return Promise.all([dispatch('fetchActiveBookings'), dispatch('fetchPastBookings')]);
  }
};

const mutations = {
  setWallet(state, { wallet, history }) {
    state.wallet = wallet;
    state.walletHistory = history;
  },
  setBusStops(state, data) {
    state.busStops = data;
  },
  addBooking(state, booking) {
    state.bookings.push(booking);
  },
  setActiveBookings(state, bookings) {
    state.activeBookings = bookings;
  },
  setPastBookings(state, bookings) {
    state.pastBookings = bookings;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  updateNotification(state, { notificationId, data }) {
    const index = state.notifications.findIndex(notification => notification.id === notificationId);

    if (index !== -1) {
      state.notifications.splice(index, 1, { ...data });
    }
  },
  setUnreadNotificationsCount(state, count) {
    state.unreadNotificationsCount = count;
  },
  reset(state) {
    Object.assign(state, defaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};