<template>
  <div v-bind="$attrs">
    <div
      class="flex items-center justify-center w-full h-screen pt-5"
      v-if="showLoader && loading"
    >
      <slot name="loading">
        <app-loading-indicator></app-loading-indicator>
      </slot>
    </div>
    <div v-if="loading || (loaded && !errorLoading)">
      <slot></slot>
    </div>
    <div
      class="pt-5 d-flex justify-content-center vh-100"
      v-if="errorLoading && !loading && !loaded"
    >
      <slot name="error">
        <div class="pt-5 text-center">
          <p class="font-medium text-gray-type-2">
            An error occured while loading {{ resourceName }} data.
          </p>
          <app-button @click="retry" class="mt-2 text-green-type-1 focus:outline-none focus:ring">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
            <span class="mt-1 ml-2">Retry</span>
          </app-button>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loaded: { type: Boolean, default: false },
    showLoader: { type: Boolean, default: false },
    loadingText: { type: String, default: "Loading..." },
    resourceName: {
      type: String,
    },
    errorLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    retry() {
      this.emitter.emit("retry");
    },
  },
};
</script>

<style>
</style>
